import React from 'react'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react' // import from 'keen-slider/react.es' for to get an ES module

import Img1 from "./1.png"
import Img2 from "./2.png"
import Img3 from "./3.png"
import Img4 from "./4.png"
import Img5 from "./5.png"
import Img6 from "./6.png"
import Img7 from "./7.png"
import Img8 from "./8.png"
import Img9 from "./9.png"
import Img10 from "./10.png"



const animation = { duration: 15000, easing: (t) => t }

export default () => {
  const [sliderRef] = useKeenSlider({
    loop: true,
    renderMode: "performance",
    breakpoints: {
      "(min-width: 400px)": {
        slides: { perView: 2, spacing: 5 },
      },
      "(min-width: 1000px)": {
        slides: { perView: 5, spacing: 10 },
      },
    },
    slides: { perView: 1 },
    drag: true,
    created(s) {
      s.moveToIdx(5, true, animation)
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation)
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation)
    },
  })
    return (
      <div ref={sliderRef} className="keen-slider py-2 md:py-16 bg-black">
        <div className="keen-slider__slide number-slide1">
            <img className="object-cover object-center" src={Img1} alt="" />
        </div>
        <div className="keen-slider__slide number-slide2">
            <img className="object-cover object-center" src={Img2} alt="" />
        </div>
        <div className="keen-slider__slide number-slide3">
            <img className="object-cover object-center" src={Img3} alt="" />
        </div>
        <div className="keen-slider__slide number-slide4">
            <img className="object-cover object-center" src={Img4} alt="" />
        </div>
        <div className="keen-slider__slide number-slide5">
            <img className="object-cover object-center" src={Img5} alt="" />
        </div>
        <div className="keen-slider__slide number-slide6">
            <img className="object-cover object-center" src={Img6} alt="" />
        </div>
        <div className="keen-slider__slide number-slide7">
            <img className="object-cover object-center" src={Img7} alt="" />
        </div>
        <div className="keen-slider__slide number-slide8">
            <img className="object-cover object-center" src={Img8} alt="" />
        </div>
        <div className="keen-slider__slide number-slide9">
            <img className="object-cover object-center" src={Img9} alt="" />
        </div>
        <div className="keen-slider__slide number-slide10">
            <img className="object-cover object-center" src={Img10} alt="" />
        </div>
      </div>
    )
  }